import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import LogoEmporio from '../../assets/loguinho.png'
import './cabecalho.estilo.scss'


export default function Cabecalho() {

    const navegacao = useNavigate()

    const [colaborador, setColaborador] = useState(false)
    const [cliente, setCliente] = useState(false)
    const [logado, setLogado] = useState(false)

    async function handleSair() {
        setCliente(false)
        setColaborador(false)
        setLogado(false)
        navegacao('/')
    }

    return (
        <>
            {logado === true && (
                <div className='conteinerCabecalho'>
                    <div className='conteinerEsquerda'>
                        <Link to='/'><img src={LogoEmporio} alt='' /></Link>
                    </div>
                    <div className='conteinerDireita'>
                        <Navbar expand='lg'>
                            <Container>
                                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                                <Navbar.Collapse id='basic-navbar-nav'>
                                    <Nav className='me-auto'>
                                        <Nav.Link href='/Combos'><p>Combos</p></Nav.Link>
                                        <Nav.Link href='/Promocoes'><p>Promoções</p></Nav.Link>
                                        {colaborador === true && (
                                            <>
                                                <Nav.Link href='/GerenciaPedidos'><p>Gerência de Pedidos</p></Nav.Link>
                                                <Nav.Link href='/GerenciaProdutos'><p>Gerência de Produtos</p></Nav.Link>
                                                <Nav.Link href='/Caixa'>Caixa</Nav.Link>
                                                <button onClick={handleSair} className='botaoSair'><p>Sair</p></button>
                                            </>
                                        )}
                                        {cliente === true && (
                                            <>
                                            <Nav.Link href='/GerenciaProdutos'><p>Pedidos</p></Nav.Link>
                                            <button onClick={handleSair} className='botaoSair'><p>Sair</p></button>                                            
                                            </>
                                        )}
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>                       
                    </div>
                </div>
            )
            }
            {logado === false && (
                <div className='conteinerCabecalho'>
                    <div className='conteinerEsquerda'>
                        <Link to='/'><img src={LogoEmporio} alt='' /></Link>
                    </div>
                    <div className='conteinerDireita'>
                    <Navbar expand='lg'>
                            <Container>
                                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                                <Navbar.Collapse id='basic-navbar-nav'>
                                    <Nav className='me-auto'>
                                        <Nav.Link href='/Combos'><p>Combos</p></Nav.Link>
                                        <Nav.Link href='/Promocoes'><p>Promoções</p></Nav.Link>                                       
                                        <Nav.Link href='/LogarClientes'><p>Área do Cliente</p></Nav.Link>
                                        <Nav.Link href='/LogarColaborador'><p>Área do Colaborador</p></Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </div>
            )}
        </>
    )
}