import React from 'react'
import Manutencao from '../assets/manut.jpg'

import './combos.estilo.scss'

export default function Combos(){
    return(
        <div className='conteinerCombos'> 
        <h1>Combos</h1>         
            <img src={Manutencao} alt=''/>
        </div>
    )
}