import React from 'react'
import Manutencao from '../assets/manut.jpg'

import './promocoes.estilo.scss'

export default function Promocoes(){
    return(
        <div className='conteinerPromocoes'>
            <h1>Promocoes</h1>         
            <img src={Manutencao} alt=''/>
        </div>
    )
}