import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Informacoes from '../Components/Informacoes'
import Cabecalho from '../Components/Cabecalho'
import Inicio from '../Inicio'
import LoginClientes from '../Cliente/Login'
import LogarColaborador from '../Colaborador/Login'

import Combos from '../Combos'
import Promocoes from '../Promocoes'

import Rodape from '../Components/Rodape'


export default function NaoAutenticado() {
    return (
        <BrowserRouter>
            <Informacoes />
            <Cabecalho />
            <Routes>
                <Route path='/' element={<Inicio />} />
                <Route path='/Combos' element={<Combos />} />
                <Route path='/Promocoes' element={<Promocoes />} />
                <Route path='/LogarClientes' element={<LoginClientes />} />
                <Route path='/LogarColaborador' element={<LogarColaborador />} />

                <Route path='*' element={<Inicio />} />
            </Routes>
            <Rodape />
        </BrowserRouter>
    )
}