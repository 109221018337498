import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask'
import InputMask from 'react-input-mask'

import { toast } from 'react-toastify'
import './loginClientes.estilo.scss'
import apiCep from '../../API/CEP/apiCep'

export default function LoginClientes() {

    const [modalAberto, setModalAberto] = useState(false)
    const [cep, setCep] = useState('')
    const [cepAtribuido, setCepAtribuido] = useState('')

    const [cpf, setCpf] = useState('')
    const [mask, setMask] = useState('')

    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [rua, setRua] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')

    const [clienteUsuario, setClienteUsuario] = useState('')
    const [clienteSenha, setClienteSenha] = useState('')

    async function Logarcliente(e) {
        e.preventDefault()
        if (!clienteUsuario || !clienteSenha) {
            toast.warning('Existem Campos em Branco', {
                toastId: 'ToastID'
            })
            return
        }
        alert(clienteUsuario + clienteSenha)
    }

    async function enviarCadastro(e) {
        e.preventDefault()
        if (!nome || !telefone || !cpf || !rua || !numero || !bairro || !cidade || !estado || !cep) {
            toast.warning('Existem Campos em Branco', {
                toastId: 'ToastID'
            })
        }
        //console.log(nome, telefone, cpf, rua, numero, complemento, bairro, cidade, estado, cep)
    }

    async function buscaCep() {
        const resposta = await apiCep(`${cep}/json`)
        setCepAtribuido(resposta.data)
    }

    useEffect(() => {
        function atribuiValores() {
            setRua(cepAtribuido.logradouro || rua)
            setBairro(cepAtribuido.bairro || bairro)
            setCidade(cepAtribuido.localidade || cidade)
            setEstado(cepAtribuido.uf || estado)
        }
        atribuiValores()
    }, [buscaCep])

    function abrirModal() {
        setModalAberto(true)
    }

    function fecharModal() {
        setModalAberto(false)
        setNome('')
        setTelefone('')
        setCpf('')
        setCep('')
        setCepAtribuido('')
        setRua('')
        setNumero('')
        setComplemento('')
        setBairro('')
        setCidade('')
        setEstado('')
        setEstado('')
    }

    return (
        <div className='conteinerLoginClientes'>
            <h1>Login de Cliente</h1>
            <form onSubmit={Logarcliente}>
                <input
                    type="text"
                    placeholder='Digite o Nome'
                    value={clienteUsuario}
                    onChange={(e) => setClienteUsuario(e.target.value)}
                />
                <input
                    type="password"
                    placeholder='Digite a Senha'
                    value={clienteSenha}
                    onChange={(e) => setClienteSenha(e.target.value)}
                />
                <button type='submit'>Enviar</button>
            </form>

            <p>Não tem cadastro? Clique <button onClick={abrirModal}>AQUI</button></p>
            <Modal
                className='Modal'
                overlayClassName='Overlay'

                isOpen={modalAberto}>
                <div className='conteinerModalClientes'>
                    <h1>Cadastrar Clientes</h1>
                    <form onSubmit={enviarCadastro}>
                        <div className='conteinerFormClientes'>
                            <div>
                                <input
                                    type="text"
                                    placeholder='Nome Completo'
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                />
                                <InputMask
                                    mask="(99) 99999-9999"
                                    type="text"
                                    placeholder='Celular'
                                    value={telefone}
                                    onChange={(e) => setTelefone(e.target.value)}
                                />
                                <CpfCnpj
                                    type="text"
                                    placeholder='CPF ou CNPJ'
                                    value={cpf}
                                    onChange={(e, type) => {
                                        setCpf(e.target.value);
                                        setMask(type === 'CPF')
                                    }}
                                />
                                <InputMask
                                    mask='99999-999'
                                    type="text"
                                    placeholder='CEP'
                                    value={cep}
                                    onChange={(e) => setCep(e.target.value)}
                                    onBlur={buscaCep}
                                />
                                <input
                                    type="text"
                                    placeholder='Rua'
                                    value={rua}
                                    onChange={(e) => setRua(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder='Numero'
                                    value={numero}
                                    onChange={(e) => setNumero(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder='Complemento'
                                    value={complemento}
                                    onChange={(e) => setComplemento(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder='Bairro'
                                    value={bairro}
                                    onChange={(e) => setBairro(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder='Cidade'
                                    value={cidade}
                                    onChange={(e) => setCidade(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder='Estado'
                                    value={estado}
                                    onChange={(e) => setEstado(e.target.value)}
                                />
                            </div>
                        </div>
                        <button type='submit'>Enviar</button>
                    </form >
                    <button onClick={fecharModal}>Fechar Modal</button>
                </div >
            </Modal >
        </div>
    )
}