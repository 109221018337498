import React, { useState } from 'react'

import { toast } from 'react-toastify'
import './loginColaborador.estilo.scss'

export default function LogarColaborador() {

    const [colaboradorUsuario, setColaboradorUsuario] = useState('')
    const [colaboradorSenha, setColaboradorSenha] = useState('')

    async function LogarColaborador(e) {
        e.preventDefault()
        if (!colaboradorUsuario || !colaboradorSenha) {
            toast.warning('Existem Campos em Branco', {
                toastId: 'ToastID'
            })
            return
        }
        alert(colaboradorUsuario + colaboradorSenha)
    }

    return (
        <div className='conteinerLoginColaborador'>
            <h1>Login de Colaborador</h1>
            <form onSubmit={LogarColaborador}>
                <input
                    type="text"
                    placeholder='Digite o Nome de Usuário'
                    value={colaboradorUsuario}
                    onChange={(e) => setColaboradorUsuario(e.target.value)}
                />
                <input
                    type="password"
                    placeholder='Digite a Senha'
                    value={colaboradorSenha}
                    onChange={(e) => setColaboradorSenha(e.target.value)}
                />
                <button type='submit'>Enviar</button>
            </form>
        </div>
    )
}