import React from 'react'

import NaoAutenticado from './naoAutenticado.routes'
import Autenticado from './autenticado.routes'

export default function Rotas(){

    const autenticado = false
    
    return(
        autenticado === true ? <Autenticado /> : <NaoAutenticado />
    )
}