import React from 'react'
import Manutencao from '../assets/manut.jpg'

import './inicio.estilo.scss'

export default function Inicio() {
    return (
        <>
            <div className='conteinerInicio'>
                <h1>Inicio</h1>
                <img src={Manutencao} alt='' />
            </div>
        </>
    )
}