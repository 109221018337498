import React from 'react'

import LogoNome from '../../assets/logoNome.png'

import Face from '../../assets/face.png'
import Insta from '../../assets/insta.png'
import Whats from '../../assets/whats.png'
import './informacoes.estilo.scss'

export default function Informacoes() {
   

    return (
        <div className='conteinerInformacoes'>
            <div className='conteinerEsquerda'>
                <img className='imagem' src={LogoNome} alt="LogoNOme" />                
            </div>
            <div className='conteinerDireita'>
                <img src={Face} alt='' />                
                <img src={Insta} alt='' />
                <img src={Whats} alt='' />
            </div>
        </div>
    )
}